<template>
  <b-form-group :label-for="id">
    <b-form-checkbox v-bind="attrs" v-on="$listeners" :id="id" @input="$emit('value-changed',bvalue)" v-model="bvalue" switch>{{label}}</b-form-checkbox>
  </b-form-group>
</template>

<script>
import {BFormGroup,BFormCheckbox} from 'bootstrap-vue'
import camelCase from 'lodash/camelCase'

export default {
  props:{
    label:String,
    value:Boolean,
  },
  computed:{
    id(){
      return camelCase(this.label)
    },
    bvalue:{
      get() { return this.value },
      set(value) { this.$emit('input', value)}
    },
    attrs(){
      const attrs = JSON.parse(JSON.stringify(this.$attrs))
      delete attrs.switch
      return attrs
    },
  },
  components:{
    BFormGroup,
    BFormCheckbox
  },
  methods:{
    handleInput(e){
      this.$emit('input', e)
    },
  }
}
</script>

<style>

</style>